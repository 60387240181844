import $ from 'jquery';
import 'slick-carousel';

jQuery(function() {
    //  Grab Cursor when grabbing a Slide
    $(".slick-slide").on("mousedown touchstart", function() {
        $(this).addClass('grabbing');
    });

    $(".slick-slide").on("mouseup touchend", function() {
        $(this).removeClass('grabbing');
    });

    // $('#slick-gammes').slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: true,
    //     fade: false,
    //     dots: false
    // });
});